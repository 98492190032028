import React from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';

type Props = PageProps & WithTranslation;

const LegalDisclaimer: React.FC<Props> = (props: Props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
  };

  return (
    <Layout options={layoutOptions} className="LegalDisclaimer">
      <div className="ElectronicAccessAgreement__generic-gap">
        <p>
          <Trans i18nKey="introParagraphs" t={t} />
        </p>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.LEGAL_DISCLAIMER)(LegalDisclaimer);
